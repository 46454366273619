import React from 'react'
import StartBuildingButton from '../../assembly_card/StartBuildingButton'
import { CreateScopeOfWorkButton, StandardStartBuildingSectionButton } from '../api/Types'
import I18n from '../../../helpers/I18n'

interface Props {
  readonly title?: string
  readonly description?: string
  readonly buttons?: {
    getStarted?: StandardStartBuildingSectionButton
    contactUs?: StandardStartBuildingSectionButton
    createScopeOfWork?: CreateScopeOfWorkButton
  }
}

const Buttons: React.FunctionComponent<Props> = ({ buttons }) => {
  const buttonsDefault = {
    getStarted: { label: 'Get Started' },
    contactUs: { label: I18n.t('views.cms.docs.contact.label') },
    createScopeOfWork: { label: 'Create Scope of Work' },
  }
  const buttonsProps = { ...buttonsDefault, ...buttons }

  return buttons !== null ? (
    <div className='docs-contact-buttons'>
      <StartBuildingButton
        label={buttonsProps.getStarted.label}
        className='button button-vention button-md'
      />
      <a href={buttons?.contactUs?.url || '/contact'} className='button button-md button-white'>
        {buttonsProps.contactUs.label}
      </a>
    </div>
  ) : null
}

const DocsContact: React.FunctionComponent<Props> = ({ title, description, buttons, children }) => {
  /* There are three available values for `descriptionContent`
    - default text: when no given descripition props is given
    - given description from props: when description props is given and string length > 0
    - <nothing>: when description props is given but string length === 0
  */
  let descriptionContent: React.ReactNode = null // default value is to display <nothing>
  if (typeof description !== 'string') {
    // no `description` prop given
    descriptionContent = (
      <p>
        Get quick answers to your questions by contacting our application engineering or technical
        support teams. Contact us by email or directly through the live chat.
      </p>
    )
  } else if (description.length > 0) {
    // `description` prop available and length > 0
    descriptionContent = <p>{description}</p>
  }

  return (
    <div className='docs-contact'>
      <h2 className='docs-contact-title'>{title || 'Did you find what you need?'}</h2>
      {descriptionContent}
      {children ? children : <Buttons buttons={buttons} />}
    </div>
  )
}

export default DocsContact
